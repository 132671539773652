// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.
// @import  "./assets/theme/_theme"; // custom
$md-primary: (
  50: #e2e8fb,
  100: #b6c6f6,
  200: #86a0f0,
  300: #567aea,
  400: #315ee6,
  500: #0d41e1,
  600: #0b3bdd,
  700: #0932d9,
  800: #072ad5,
  900: #031ccd,
  A100: #f5f6ff,
  A200: #c2c7ff,
  A400: #8f98ff,
  A700: #7580ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

$custom-typography: mat.define-legacy-typography-config(
  $font-family: '"Open Sans", sans-serif',
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// $intrxn-primary: mat.define-palette(mat.$indigo-palette);
// $intrxn-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$intrxn-primary: mat.define-palette($md-primary);
$intrxn-accent: mat.define-palette($md-primary, A200, A100, A400);

// The warn palette is optional (defaults to red).
$intrxn-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$intrxn-theme: mat.define-light-theme(
  (
    color: (
      primary: $intrxn-primary,
      accent: $intrxn-accent,
      warn: $intrxn-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($intrxn-theme);

/* You can add global styles to this file, and also import other style files */
// html,
// body {
// }
// body { margin: 0; /* font-family: Roboto, "Helvetica Neue", sans-serif; */ }

/* variables */
$font-family: "Open Sans", sans-serif;

:root {
  /* color */
  --primary: #0d41e1;
  --white: #ffffff;
  --red: #f05d5e;
  --green: #87cd67;
  --yellow: #ecbd10;
  --black: #111111;

  --gray_900: #373a3e;
  --gray_500: #a5adba;
  --gray_400: #d5d5d8;
  --gray_100: #f5f6f7;
}
html,
body {
  height: 100%;
  margin: 0;
  letter-spacing: 0px !important;
  font-family: $font-family;
  font-style: normal;
  background-color: var(--white);
}

p,
button,
textarea,
input,
div,
h1,
h2,
h3,
h4,
h5,
h6,
span,
select {
  font-family: $font-family;
  font-style: normal;
}

md-icon {
  font-family: "Material Icons" !important;
}

/**********************  Common **************************/

h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: var(--primary);
  margin: 0 0 12px 0px;
}
h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #373a3e;
}

h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin: 0 0 8px 0;
}

h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin: 0 0 8px 0;
}

p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 12px 0px;
  color: #373a3e;
}

.gws_container {
  padding: 0 24px;
  box-sizing: border-box;
}
.gws_padding_right {
  padding-right: 24px;
  box-sizing: border-box;
}
.gws_padding_left {
  padding-left: 24px;
  box-sizing: border-box;
}

.gws_c_p {
  cursor: pointer;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
/********************* scroll bar *****************/
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background: var(--gray_500);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
}
/***************** button ***********************/
a {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #373a3e;
  text-decoration: none;
}

a.link {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #0d41e1;
}

a,
button {
  cursor: pointer;
}

@mixin gws_btn_font {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  padding: 6px 12px;
  border-radius: 3px;
  border: none;
}

.gws_btn_primary {
  @include gws_btn_font;
  background: var(--primary);
  color: var(--white);
  cursor: pointer;
}

.gws_btn_secondary {
  @include gws_btn_font;
  background: var(--white);
  color: var(--primary);
  cursor: pointer;
}

.gws_btn_disabled {
  @include gws_btn_font;
  color: var(--gray_500);
  background: var(--gray_100);
  cursor: not-allowed;
}

/********************** INPUT *****************/

.gws_form_control {
  // width: 300px;
  width: 100%;
  height: auto;
  margin: 0 0 15px 0;

  input,
  select,
  textarea {
    width: 100%;
    height: 40px;
    background: #ffffff;
    border: 1px solid #bfbfbf;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 8px 0 0;
    padding: 8px;

    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: #535353 !important;

    &[disabled] {
      border: 1px solid rgba(191, 191, 191, 0.2);
      color: #696969;
    }
  }
}
// arrow style
select {
  background: url("data:image/svg+xml,<svg width='14' height='8' viewBox='0 0 14 8' fill='black' xmlns='http://www.w3.org/2000/svg'><path d='M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z' /></svg>")
    no-repeat !important;

  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}

input.search {
  background: url("assets/images/search.svg") no-repeat !important;
  background-position: calc(100% - 0.5rem) center !important;
}

input.select,
.select_type1 {
  background: url("data:image/svg+xml,<svg width='14' height='8' viewBox='0 0 14 8' fill='black' xmlns='http://www.w3.org/2000/svg'><path d='M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z' /></svg>")
    no-repeat !important;
  background-position: calc(100% - 0.75rem) center !important;
}

.is-invalid {
  border: 1px solid var(--red) !important;
}
.is-invalid::placeholder {
  color: var(--red);
}

.gws_label_optinal {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  label {
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: #1a1a1a;
    padding-right: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    mat-icon {
      font-size: 18px;
      width: 18px;
      height: 18px;
      margin-left: 5px;
      color: var(--gray_900);
    }
  }

  & > span {
    flex-shrink: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: var(--primary);
    cursor: pointer;
    // display: none;
  }
}

.gws_input_error_hint {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .gws_form_errors {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    color: var(--red);
    // display: none;

    mat-icon {
      width: 16px !important;
      height: 16px !important;
      font-size: 16px;
      margin: 0 8px 0 0;
    }

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }
  .gws_hint {
    flex-shrink: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: var(--primary);
    margin-top: 8px;
    // display: none;
  }
}

.gws_label_card {
  margin: 10px 10px 0 0;
  padding: 2px 10px;
  background: var(--gray_400);
  border-radius: 15px;

  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #111111;
  display: inline-block;
}

.gws_label_error {
  color: var(--red);
  background: rgba(234, 67, 53, 0.15);
}

.gws_label_sucess {
  color: var(--green);
  background: rgba(52, 168, 83, 0.15);
}

.gws_divider_or {
  width: 100%;
  height: 40px;
  margin: 5px 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: "";
    width: 100%;
    height: 1px;
    background: #e0e0e0;
    position: absolute;
    top: 50%;
    z-index: -1;
  }

  span {
    padding: 10px;
    background: #ffffff;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #a6a6a6;
  }
}

.gws_form_control_otp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 34px 0 25px 0;

  .gws_otp {
    width: 40px;
    height: 40px;
    padding: 8px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #535353;
    text-align: center;
    background: #ffffff;
    border: 1px solid #bfbfbf;
    box-sizing: border-box;
    border-radius: 8px;

    -webkit-text-security: disc;
  }
}

/**************** social login btn *******************/
.gws_social_btn {
  display: flex;
  align-items: center;

  border-radius: 2px;
  height: 32px;
  cursor: pointer;
  padding: 3px;
  box-sizing: border-box;

  span {
    height: 20px;
    width: 20px;
    background: #ffffff;
    border-radius: 2px;
    padding: 2px;
    // margin-right: 10px;
    // margin-left: 3px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  p {
    flex: 1 auto;

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    text-align: center;
    margin: 0;
  }
}

.google_color {
  background: #4285f4;
}

.apple_color {
  background: #000000;
}

.google_color {
  margin-bottom: 10px;
}

.gws_help {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #797878;
  text-align: center;
}

.color_primary {
  color: var(--primary) !important;
}

.mat-icon {
  cursor: pointer;
}

.color_white {
  color: var(--white);
}

.color_green {
  color: var(--green);
}

.color_red {
  color: var(--red);
}

.color_gray {
  color: #797878 !important;
}

.color_black {
  color: #000000 !important;
}

.gws_flex_column {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gws_flex_top_item {
  flex: 1 0;
}

//  flexbox

.gws_flexbox {
  display: flexbox;
  flex-direction: row;
  min-height: 100%;
  height: 100%;
}

.gws_flex_cell:first {
  width: 20%;
  background-color: #4285f4;
}

// dashboard
.mat-drawer-container {
  background: var(--white);
}

.gws_comp_profile {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  h6 {
    font-weight: 700;
    margin: 0 0 0 10px;
    color: #373a3e;
  }
}
.gws_profile_badge {
  display: block;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(13, 66, 225, 0.15);

  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: var(--primary);
}

/** account setup */
.gws_container_m_900 {
  max-width: 900px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .gws_left_container {
    width: 416px;
  }

  .gws_right_container {
    width: 425px;
  }
}

@media screen and (max-width: 900px) {
  .gws_container_m_900 {
    .gws_left_container {
      width: 100%;
    }

    .gws_right_container {
      width: 100%;
    }
  }

  .summary-dialog {
    max-width: 400px !important;
    min-width: auto !important;
  }
}

/************  steper *************/
.gws_stepper_acc_activation {
  width: 70%;
  padding: 17px 0;

  h2 {
    margin-bottom: 5px;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #111111;
    margin-bottom: 23px;
  }
}

.gws_stepper_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .gws_step {
    padding: 5px;
    position: relative;
    height: 65px;

    display: flex;
    flex-direction: row;

    h6 {
      margin-top: 4px;
    }
  }
}
.gws_step_progress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-right: 20px;

  .bubble {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  .progress {
    width: 5px;
    height: calc(100% - 34px);
    border-radius: 2px;
  }
}

.gws_step .active {
  .bubble {
    background-color: var(--green);
  }
  .progress {
    background-color: var(--green);
  }
}
.gws_step .inprogress {
  .bubble {
    // background-color: var(--primary);
    border: 1px solid var(--green);
  }
  .progress {
    background-color: var(--gray_400);
  }
}
.gws_step .yettostart {
  .bubble {
    border: 1px solid var(--gray_400);
  }
  .progress {
    background-color: var(--gray_400);
  }
}

/****************  drop drop ****************/
.gws_drop_drop_container {
  width: 100%;
  height: 108px;
  background: #ffffff;
  border: 1px dashed #bfbfbf;
  box-sizing: border-box;
  border-radius: 2px;
  margin: 8px 0 0;
  padding: 2rem;
  text-align: center;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  input {
    opacity: 0;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
  }
  .content-box {
    margin-top: -15px;
    p {
      font-size: 10px;
      line-height: 10px;
      span {
        font-weight: 700;
      }
    }
    .mat-icon {
      color: #d5d5d8;
    }
    .supported {
      font-weight: 600;
      font-size: 10px;
      line-height: 10px;
      text-align: center;
      color: #797878;
      opacity: 0.7;
      display: block;
      span {
        font-weight: 700;
      }
    }
  }
}

.gws_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    margin: 10px 0;
  }
}

.m-40 {
  margin: 40px 0;
}

/************* upload file *******/
.show_file_name {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  span {
    margin-right: 20px;
  }
  mat-icon {
    color: #696969;
  }
}
/************ dialog ******/

.cdk-overlay-dark-backdrop {
  background: rgba(255, 255, 255, 0.75);
}
.mat-mdc-dialog-container {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .gws_container,
  .content {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

/************** snack bar ************/
.mat-mdc-snack-bar-container {
  background-color: var(--white);
  color: var(--gray_900);

  .mat-mdc-button {
    color: var(--primary);
    font-weight: 400;
    font-size: 12px;
  }
}

.mat-mdc-snack-bar-container.snack-sucess {
  background-color: var(--green);
  color: var(--white);

  .mat-mdc-button {
    color: var(--gray_100);
  }
}

.mat-mdc-snack-bar-container.snack-error {
  background-color: var(--red);
  color: var(--white);

  .mat-mdc-button {
    color: var(--gray_100);
  }
}

.mat-mdc-snack-bar-container.snack-warn {
  background-color: var(--black);
  color: var(--white);

  .mat-mdc-button {
    color: var(--gray_100);
  }
}

.mat-mdc-snack-bar-container.snack-info {
  background-color: var(--primary);
  color: var(--white);

  .mat-mdc-button {
    color: var(--gray_100);
  }
}

/****** mat dropdown custom *********/

.gws_mat_select {
  background: rgba(9, 30, 66, 0.04);
  padding: 5px 12px;
  height: 32px !important;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  color: #373a3e !important;
  border-radius: 3px;
  // width: 93px;
}
.mat-mdc-select {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #373a3e !important;
}
.mat-mdc-select-panel-above {
  width: 170px !important;
}

.mat-mdc-select-placeholder {
  color: #373a3e !important;
}
.mat-mdc-select-panel {
  min-width: 150px !important;
  margin-top: 25px !important;
  margin-left: 6px;
  padding: 5px 0 !important;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1) !important;
  border-radius: 0px 0px 5px 5px;
  box-sizing: border-box;
}
.mat-mdc-option {
  height: 28px !important;
  min-height:28px !important;
  color: var(--primary);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.mat-select-panel .mat-mdc-option.mat-selected:not(.mat-mdc-option-multiple),
.mat-option.mat-active {
  background: none !important;
}
.gws_mat_options_divider {
  display: block;
  width: calc(100% - 32px);
  height: 1px;
  background-color: #d5d5d8;
  margin: 2px 16px;
}

.mat-mdc-select-arrow-wrapper {
  background: url("data:image/svg+xml,<svg width='14' height='8' viewBox='0 0 14 8' fill='373A3E' xmlns='http://www.w3.org/2000/svg'><path d='M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z' /></svg>")
    no-repeat !important;
  background-position: center center !important;
  background-size: 10px 10px !important;
}

.mat-mdc-select-arrow {
  opacity: 0 !important;
}
.gws_select_priamry {
  .mat-mdc-select-arrow-wrapper {
    filter: invert(11%) sepia(100%) saturate(6524%) hue-rotate(233deg)
      brightness(93%) contrast(91%);
  }
 .mat-mdc-select-placeholder {
    color: var(--primary) !important;
  }
}
.mat-mdc-option.mat-active,
.mat-mdc-select-value {
  color: var(--primary) !important;
}

.select-border-only {
  background-color: var(--white) !important;
  border: 2px solid #dfe1e6;
  border-radius: 3px;
  color: #373a3e !important;
  .mat-mdc-select-arrow-wrapper {
    background-size: 15px 15px !important;
  }
}

.select-normal {
  background-color: var(--white) !important;
  border: 1px solid #dfe1e6;
  border-radius: 3px;
  color: #373a3e !important;
  padding: 5px;

  .gws-select {
    color: var(--gray_900) !important;
    font-size: 14px;
    .mat-mdc-option,
    .mat-option.mat-active,
    .mat-select-value {
      color: var(--gray_900) !important;
    }
  }
}

/********************  date picker *******************/
.gws_date_picker {
  background: rgba(9, 30, 66, 0.04);
  padding: 0 10px 0 12px !important;
  height: 32px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  color: #373a3e !important;
  border-radius: 3px;

  .date_input_conatiner {
    display: flex;
    align-items: center;
    height: inherit;

    input {
      height: 100%;
      border: none;
      margin: 0;
      padding: 0;
      border-radius: unset;
      background: transparent;

      font-weight: 400 !important;
      font-size: 14px !important;
      color: #373a3e !important;

      &::placeholder {
        color: #373a3e !important;
      }
      &:focus {
        outline: none !important;
        border: transparent !important;
      }
    }
    .mat-datepicker-toggle {
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      .mat-mdc-icon-button {
        height: 100%;
        width: 100%;
        background-image: url("assets/images/calendar.svg");
        background-size: 20px 20px;
        background-position: center center;

        svg {
          display: none;
        }
      }
    }
  }
}

.gws_date_picker_normal {
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  color: #373a3e !important;
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  margin-top: 8px;

  .date_input_conatiner {
    display: flex;
    align-items: center;
    height: inherit;

    input {
      margin: 0;
      border: none;
    }

    .mat-datepicker-toggle {
      font-size: 30px;
      height: 30px;
      width: 30px;
      margin: 0 10px;
      display: flex;
      align-items: center;
      .mat-mdc-icon-button {
        height: 100%;
        width: 100%;
        background-image: url("assets/images/calendar.svg");
        background-size: 25px 25px;
        background-position: center center;

        svg {
          display: none;
        }
      }
    }
  }
}

/*********** input v2 **************/
input.search {
  padding-right: 30px;
}
input.search,
input.gws_input_v2,
input.select,
mat-select.select {
  height: 32px;
  font-size: 14px !important;
  background: #fafbfc;
  border: 2px solid #dfe1e6;
  border-radius: 3px;

  color: #373a3e !important;
  &::placeholder {
    color: #373a3e !important;
  }
}

.gws_textarea {
  width: 100%;
  height: 60px;
  font-size: 14px !important;
  background: #fafbfc;
  border: 2px solid #dfe1e6;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #797878 !important;
  box-sizing: border-box;
}

/******** tab *******/
.mat-mdc-tab {
  flex-grow: initial !important;
}

.ges_tabs_container {
 .mat-mdc-tab {
  justify-content:left
 }
}

.mdc-tab__text-label {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: $font-family;
  letter-spacing: 0px !important;
}

.mat-mdc-tab-nav-bar, .mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}



.gws_chip {
  display: block;
  padding: 1px 8px;
  margin: 0 5px;
  border-radius: 11px;
  background: rgba(0, 0, 0, 0.097);

  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #373a3e;
}

.mat-mdc-tab-label-active {
  color: var(--primary);
  .gws_chip {
    background: rgba(13, 65, 225, 0.15);
    color: inherit;
  }
}

/************ table **********/
.gws_table_container {
  margin-top: 10px;
  border-bottom: 1px solid #c4c4c4;
  table {
    width: 100%;
    padding: 10px;
  }
}

.mat-mdc-row .mat-mdc-cell {
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
  cursor: pointer;

  padding-right: 5px !important;
  padding-left: 5px !important;
}

.mat-mdc-row:hover .mat-mdc-cell {
  border-color: var(--gray_400);
}

tr.mat-mdc-header-row {
  height: 30px !important;
  border-bottom: 2px solid #c4c4c4 !important;
}

.mat-mdc-header-cell {
  font-weight: 600 !important;
  font-size: 12px;
  line-height: 16px !important;
  color: #6b778c;
  letter-spacing: 0px;
}

tr.mat-mdc-row {
  height: 40px !important;
}
.mat-mdc-cell,
tr.mat-mdc-row {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #172b4d !important;
}

.mat-mdc-cell > span {
  display: inline-block;
  justify-content: center;
  padding: 3px 12px;
  margin: 0 5px;
  border-radius: 11px;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
}
span.paid {
  color: #87cd67;
  background: rgba(52, 168, 83, 0.15);
}
span.overdue {
  color: #f05d5e;
  background: rgba(234, 67, 53, 0.15);
}
span.awaitingpayment, .awaiting_gpayment, .sent {
  color: #0d41e1;
  background: rgba(13, 65, 225, 0.15);
}
span.archived {
  color: #111111;
  background: #e2d0a8;
}
span.submitted {
  color: #111111;
  background: #e0e0e0;
}
td.mat-mdc-cell:first-of-type,
th.mat-mdc-header-cell:first-of-type {
  padding-left: 0 !important;
}

.paid {
  color: #87cd67 !important;
  background: rgba(52, 168, 83, 0.15) !important;
}

/**************** pagination **************/
/**************** message badge **************/
.message_badge {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  margin: 5px 0;
  background: rgba(13, 65, 225, 0.15);
  color: var(--primary);
  border-radius: 5px;
  box-sizing: border-box;

  mat-icon {
    color: inherit;
  }

  span {
    margin: 0 0 0 10px;
    color: #111111;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}

/*********  toggle button *********/
.mat-mdc-button-toggle-group {
  width: 100%;
  margin: 5px 0;
  border-radius: 5px;
}

.mat-mdc-button-toggle {
  width: 50%;
}
.mat-mdc-button-toggle-checked {
  background-color: var(--primary);
  color: var(--white) !important;
  border-radius: 5px !important;
}
.mat-mdc-button-toggle-label-content {
  line-height: 43px !important;
}
.summary-dialog {
  max-width: 550px !important;
  min-width: 550px !important;
}
@media screen and (max-width: 900px) {
  .summary-dialog {
    max-width: 400px !important;
    min-width: auto !important;
  }
}

.custom-payout-menu {
  padding: 15px;
  max-width: 400px !important;
  min-width: 350px !important;
}

.external {
  background: rgba(7, 200, 249, 0.15);
  color: #07c8f9;
}

.internal,
.subscription {
  background: rgba(10, 133, 237, 0.15);
  color: #0a85ed;
}

.enabled {
  background: #07c8f9;
  color: #fff;
}

.highlight {
  background: #d1ddff;
}

.create_offer {
  width: 90%;
  height: 70vh;
  max-height: 525px;
  min-height: 525px;
  .mdc-card {
    flex-direction: row;
    border: 0 !important;
  }
}

.timepicker-overlay,
.timepicker-backdrop-overlay {
  z-index: 99999 !important;
}

.timepicker {
  .timepicker__header {
    background-color: var(--primary) !important;
  }

  .clock-face__clock-hand {
    background-color: var(--primary) !important;

    &::after {
      background-color: var(--primary) !important;
    }

    &::before {
      border-color: var(--primary) !important;
    }
  }

  .clock-face__number {
    > span.active {
      background-color: var(--primary) !important;
    }
  }

  button:not(.timepicker-dial__item) {
    color: var(--primary) !important;
  }
}

.ngx-material-timepicker-toggle:disabled:disabled {
  opacity: 0.4;
}

.date_picker .suffix .mat-mdc-icon-button {
  margin: 0;
  background-repeat: no-repeat;
}

ngx-material-timepicker-toggle.suffix {
  position: relative;
  right: 34px;
}

.offer_list {
  .mat-mdc-list-item  {
    padding-left: 0px;
    padding-right: 0px;
    .mdc-list-item__content {
      justify-content: space-between;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      height: inherit;
      .list-item__primary-text {
        font-size: 100;
      }
    }
  }
  

  .firstchild {
    width: 50%;
    display: inline-block;
  }
  .disaply_block {
    width: 50%;
    display: inline-block;
  }
  .mat-mdc-list-item {
    height: 42px !important;
  }
}
.subscription_details {
  .mdc-list-item {
    height: 54px !important;
  }
  .firstchild {
    width: 40% !important;
  }
  .disaply_block {
    width: 60%;
  }
}

.firstchild {
  color: #6b778c !important;
}

.tab-header {
  .mat-tab-links {
    display: block;
  }
  .mat-mdc-tab-link-container {
    overflow: auto;
    z-index: auto;
  }
  .mat-mdc-tab-link {
    .mdc-tab__text-label {
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
      color: #373a3e;
      opacity: 1;
    }
  }
}

.offer_scroll {
  width: 455px;
  overflow-y: scroll;
  max-height: 440px;
  box-shadow: none !important;
  // -ms-overflow-style: none; /* Internet Explorer 10+ */
  // scrollbar-width: none; /* Firefox */
  padding: 20px;

  .gws_chip {
    display: inline-block;
    padding: 5px 15px;
    margin-left: 0;
  }

  a.gws_btn_secondary:hover {
    text-decoration: underline;
  }
}
// .offer_scroll::-webkit-scrollbar {
//   display: none; /* Safari and Chrome */
// }

.recent_subscriptions {
  .mat-mdc-header-row {
    display: none;
  }
  .mat-mdc-row {
    border-bottom: 1px solid #eee !important;
  }
  .mat-mdc-cell {
    font-size: 13px !important;
  }
}

body .interNotes {
  height: auto !important;
  .mat-mdc-list-item-content {
    display: inline-block !important;
    width: 100%;
    padding-left: 49px !important;
  }
}

.overflow-fixed {
  overflow: hidden !important;
}

.filter_bar.active {
  border: 1px solid var(--primary);
}

.filter_bar {
  display: flex !important;
  align-items: center;
  min-width: 265px;
  padding: 0px;
  justify-content: space-evenly;
  cursor: pointer;
  &:nth-child(1) {
    border-left: 2px solid #0799ff;
  }
  &:nth-child(2) {
    border-left: 2px solid #ff9d42;
  }
  &:nth-child(3) {
    border-left: 2px solid #07c8f9;
  }
  &:nth-child(4) {
    border-left: 2px solid rgb(94, 190, 91);
  }

  .close {
    position: absolute;
    right: 0;
    top: 7px;
    font-size: 18px;
    cursor: pointer;
  }
  .mat-mdc-card-header-text {
    margin: 0 0px;
    display: flex;
    justify-content: left;
    grid-gap: 18px;
    gap: 18px;
    height: 55px;
    align-items: center;
    .mat-mdc-card-subtitle {
      margin: 0;
      font-size: 14px;
      color: #5c5c5c;
    }
    .mat-mdc-card-title {
      margin-bottom: 6px;
      font-size: 25px;
    }
  }

  .mat-mdc-card-header {
    padding: 10px;
  }
}

.invoice_details {
  th.mat-mdc-header-cell:last-of-type,
  td.mat-mdc-cell:last-of-type,
  td.mat-mdc-footer-cell:last-of-type {
    padding-right: 0px;
  }

  .mat-mdc-cell > span {
    font-size: 12px;
    padding: 7px 0px 0px 0px;
    display: block;
    margin-bottom: 0px;
  }
  .font-small {
    font-size: 10px !important;
  }

  .gws_chip {
    display: inline-block !important;
    padding: 5px 11px !important;
    margin-top: 5px;
  }
  .mat-mdc-row .mat-mdc-cell {
    border-color: var(--gray_400);
    padding: 10px;
  }
}

.sidebar-container {
  .mat-drawer-backdrop,
  .example-sidenav {
    position: fixed;
  }

  .example-sidenav {
    top: 56px;
    .mat-drawer-inner-container {
      overflow: hidden;
    }
  }
}

.network_tab {
  padding: 15px 5px 11px 13px;
  background: #f5f5f5;
  mat-chip.mat-mdc-chip img {
    margin-right: 5px;
  }
  .gws_label_optinal {
    margin-bottom: 12px;
  }
  .network_row {
    margin-bottom: 18px;
  }
  .currency_row {
    margin-bottom: 15px;
  }
  .gws_mat_select {
    height: 35px !important;
    padding: 10px;
  }
}

.create_wallet {
  .walled_network {
    max-width: 400px;
  }
}
.wallet-flow {
  .walled_network {
    max-width: 100%;
  }
}
.invoice_tab {
  width: auto;
}

.walled_network {
  margin-top: 15px;
  border: 1px solid #bfbfbf;
  padding: 10px;
  max-width: 380px;
  border-radius: 8px;
}

.subscription_select {
  max-width: 100%;
}

.network_option.mat-mdc-active,
.network_option,
.walled_network .mat-mdc-select-value,
.subscription_filter .mat-mdc-select-value {
  color: var(--black) !important;
  font-size: 14px;
  text-transform: capitalize;
}

.subscription_filter {
  padding: 5px;
  border: 2px solid #dfe1e6;
  height: 32px;
  width: 182px !important;
  margin-top: 8px;
}

.billing_frq,
.billing_curr {
  margin-top: 8px;
}

.billing_frq {
  max-width: 130px;
  margin-right: 5px;
  height: 40px;
}

.tax_box {
  margin-top: 8px;
  margin-right: 5px;
  height: 40px;
  max-width: 60px;
}

.billing_curr {
  max-width: 120px;
  height: 40;
}

.text-capitalize {
  text-transform: capitalize;
}
.created {
  background: var(--gray_400);
}

.gws_chip.active {
  background: var(--primary);
  color: var(--white) !important;
}

.completed {
  background: var(--green);
  color: var(--white) !important;
}

.expired,
.cancelled {
  background: var(--red);
  color: var(--white) !important;
}

.paused,
.pending {
  background: var(--yellow);
  color: var(--white) !important;
}

.networkList,
.currencyList {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
  h6 {
    margin-bottom: 4px;
  }
  span {
    font-size: 14px;
  }
  ul {
    display: inline-block;
    width: 100%;
    margin: 0;
    list-style: none;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      grid-gap: 3px;
      gap: 3px;
      float: left;
      font-size: 14px;
      margin: 0px 5px 0px 0px;
    }
  }
}

.chain_filter {
  .mat-mdc-option-text {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #000;
  }
}
.code {
  word-break: break-all;
}
@media (min-width: 1600px) {
  .offer_scroll {
    max-height: 500px;
  }
}

@media (min-width: 1920px) {
  .offer_scroll {
    max-height: 695px;
  }
}

@media (min-width: 2560) {
  .offer_scroll {
    max-height: 888px;
  }
}

// Need to remove this
.mat-mdc-option.mat-option-disabled {
  color: rgb(70 70 70 / 38%) !important;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  z-index: 99999999999;
  top: -43px;
  left: -37px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.d-none {
  display: none;
}

.mat-mdc-select {
  box-sizing: border-box;
  font-size: 14px;
}

.info {
  color: #0d41e1;
  font-size: 14px;
  background: transparent;
  cursor: pointer;
  margin-left: 2px;
}

.mat-mdc-select-disabled {
  background: #eeee;
}

.walletconnect-modal__base {
  max-width: 290px !important;
}
.kyc_form {
  .date_input_conatiner {
    .mat-datepicker-toggle {
          .mat-mdc-icon-button {
                background-repeat: no-repeat;
          }
    }
  }
}

.fullwidth {
  .mat-mdc-select {
    background-size: 17px 18px !important;
    padding-left: 35px !important;
    background-position: 11px 11px !important;
    max-width: 100%;
  }
  .USDC{
    background:  url("assets/images/usdc.svg")  center / contain no-repeat;
  }
  
  .USDT {
    background:  url("assets/images/usdt.svg")  center / contain no-repeat;
  }

  .DAI {
    background:  url("assets/images/dai.svg")  center / contain no-repeat;
  }

  .MATIC {
    background:  url("assets/images/polygon.svg")  center / contain no-repeat;
  }
  .mat-mdc-select-disabled {
    background-color: #eee !important;
  }
}

.position-relative {
  position: relative;
  .ntw {
    position: absolute;
    top: 20px;
    right: 32px;
    span {
      background: #0d41e1;
      padding: 4px 5px;
      border-radius: 4px;
      color: #fff;
      font-size: 12px;
    }
  }
}

.selectCountry {
  height: 40px;
  background: #ffffff;
  border: 1px solid #dfe1e6;
  border-radius: 10px;
  padding: 12px;
}
.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-3 {
  margin-top: 3em;
}
.mt-2 {
  margin-top: 2em;
}
.ellipsis {
  width: 105px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.choose_partner {
  .example-radio-group {
    display: inline-block;
    width: 100%;
    .mat-mdc-radio-checked {
      border-color: var(--primary) !important;
    }
    .mat-mdc-radio-button {
      border-radius: 5px;
      border: 1px solid #eee;
      width: 100%;
      position: relative;
      margin-bottom: 7px;
      label.mat-mdc-radio-label {
        width: 100%;
        display: block;
        padding: 10px 5px;
        span.mat-mdc-radio-container {
          opacity: 0;
          position: absolute;
          left: 50%;
          top: 32%;
        }
        span.mat-mdc-radio-label-content {
          margin: 0px 2px;
          padding: 0;
          display: flex;
          align-items: center;
          position: relative;
          gap: 12px;
          .right-align {
            position: absolute;
            right: 10px;
            span.price {
              display: block;
              font-size: 14px;
              font-weight: 600;
            }
            span.percentage {
              display: block;
              float: revert;
              text-align: right;
              font-size: 12px;
              color: #f94343;
              font-weight: 600;
              margin-top: 2px;
            }
        }
          img {
            width: 30px;
            border-radius: 100%;
          }
        }
      }
    }
  }
}

.owerflowhideen {
  .mat-mdc-dialog-container {
    padding: 24px 12px 24px 24px;
    .gws_flex_container {
      max-height: 475px;
      overflow-x: hidden;
      overflow-y: scroll;
      padding-right: 14px; 
    }
  }
}

.refreshquotation {
  position: absolute;
  top: 0;
  background: var(--primary);
  width: 100%;
  left: 0;
  color: #fff;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0 0;

  p {
        color: #fff;
        font-size: 14px;
  }

  span#timer {
        font-size: 12px;
  }

  .mat-icon {
        cursor: pointer;
  }
}

.pt-25 {
  padding-top: 25px !important;
}

.d-flex {
  display: flex;
  gap: 10px;
}

.justify-content-end {
  justify-content: flex-end;
}
.justify-content-center {
  justify-content: center;
}

.align-item-center {
  align-items: center;
}

.modal-container {
  .header {
        display: flex;
        justify-content: space-between;

        h2 {
              font-size: 20px;
        }

        .mat-icon {
              cursor: pointer;
        }
      }
      .gws_btn_secondary {
        border: 1px solid var(--primary) !important;
        padding: 5px 12px;
      }
  }

 
.now-arrow::-webkit-inner-spin-button,
.now-arrow::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.oop_message {
  background: #fff5f4;
  padding: 20px;
  
  h3 {
    color: #e84140;
  }
  p {
    color: #e84140;
  }
  .gws_btn_primary {
    background-color: #e84140;
  }
}

.tab-details {
  .mat-mdc-tab-label,
  .mat-mdc-tab-link {
        font-size: 15px !important;
        font-weight: 600 !important;
  }
  table.mat-mdc-table {
    width: 100%;
  }
}

.mat-mdc-paginator-page-size {
  .mat-mdc-form-field-appearance-legacy .mat-form-field-infix {
    padding: 1.4375em 0 !important;
  }
}

.gws_title_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .create_invoice_container {
    display: flex;
    justify-items: center;
    flex-wrap: wrap;

    p {
      margin: 5px 20px 5px 10px;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      margin: 2px 8px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    .icon_btn {
      padding: 0px 12px;
      border: 1px solid var(--primary);
      border-radius: 3px;
      .mat-icon {
        height: 20px;
        font-size: 20px;
      }
    }
  }
}

.bold{
  font-weight: bolder !important;
}

.cdk-overlay-container {
  z-index: 2;
}

.wallet_menu {
    width: 375px;
  .name_box {
      display: flex !important;
      padding: 24px 10px 14px 10px!important;
      align-items: center;
      .mat-icon {
        font-size: 18px;
        margin-left: 8px;
        margin-top: 5px;
        cursor: pointer;
      }
  } 
  .sign_box {
    display: inline-block;
    width: 50%;
    float: none;
    margin: 0 auto;
}
}

.paymentPage {
  .cdk-overlay-container {
    z-index: 999999;
  }
}

body .noicon {
  background: transparent !important;
}

.smallicn {
  width: 20px !important;
  height: 20px !important;
  font-size: 18px !important;
  position: absolute;
  right: -12px;
  top: 12px;
}


.coloredpc::placeholder {
  opacity: 0.3;
}

.mat-mdc-card {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  position: relative;
  padding: 16px;
  border-radius: 4px;
}

.gws_mat-list-item, .mdc-list-item__primary-text {
  font-family: $font-family !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  letter-spacing: 0px !important;
}

.mdc-list-item__primary-text {
      display: flex;
    }

    .mat-mdc-dialog-container {
      box-shadow: none;
    .mdc-dialog__container {
      .mat-mdc-dialog-surface {
        padding: 20px 20px 15px 20px;
      }
    }
  }

.mdc-menu-surface--open {
  .mdc-list-item__primary-text {
    font-weight: normal !important;
    color: var(--primary) !important;
  }
}

.mdc-list-item__primary-text::before, .mdc-list-item--with-three-lines .mdc-list-item__primary-text::before {
   height: auto !important;
  content: "";

}
.setting-menu {
  flex-direction: row !important;
}

.copy_icon {
  .copy {
    overflow: visible !important;
  }
}
.space-between {
  justify-content: space-between;
}
.payment-flow  {
  .payment {
    min-width: auto !important;
    width: 100% !important;
    box-sizing: border-box;
  }
}

.w-100 {
  width: 100%;
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
  background-color: var(--primary) !important;
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: var(--primary) !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: var(--primary) !important;
}


.bank_details {
  ul {
    padding-left: 0;
    li {
      margin-bottom: 5px;
      font-size: 14px;
    }
  }
}
.mdc-tab__text-label {
  font-size: 16px !important;
}
.active {
  .mdc-tab__text-label {
        font-weight: 600 !important;
        color: var(--primary)  !important;
        font-size: 16px !important;
  }
}

.no_record_found {
  font-size: 15px;
  padding: 12px;
}

.mat-mdc-paginator-page-size-select {
  width: 55px !important;
  .mat-mdc-text-field-wrapper {
    height: 30px  !important;
    padding: 0px 10px !important;
  }
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 3px !important;
  padding-bottom: 0px !important;
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 30px  !important;
  width: max-content !important;
}